var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormProvider, { RHFAsyncSelect, RHFTextField } from '../../../../components/hook-form';
import { useSnackbar } from '../../../../components/snackbar';
import usePermission from '../../../../hooks/usePermission';
import { useLocales } from '../../../../locales';
import { getAllOffices } from '../../../../redux/slices/offices/actions';
import { getUsers } from '../../../../redux/slices/users/actions';
import { dispatch } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { generalCashRegisterValidation } from '../../../../Validation';
import { createBooksCashDeskPayment, updateBooksCashDeskPayment, } from '../../../../redux/slices/bookCashDeskPayments/actions';
export default function BooksCashDeskPaymentsForm(_a) {
    var _this = this;
    var _b = _a.isEdit, isEdit = _b === void 0 ? false : _b, _c = _a.paymentDetails, paymentDetails = _c === void 0 ? false : _c, currentBooksCashDeskPayment = _a.currentBooksCashDeskPayment;
    var navigate = useNavigate();
    var translate = useLocales().translate;
    var isSuperAdmin = usePermission().isSuperAdmin;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var defaultValues = useMemo(function () { return ({
        amount: (currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment.amount) !== undefined
            ? currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment.amount
            : undefined,
        cashedTo: (currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment.cashedTo) || null,
        office: (currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment.office) || null,
        notes: (currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment.notes) || '',
    }); }, [currentBooksCashDeskPayment]);
    var methods = useForm({
        resolver: yupResolver(generalCashRegisterValidation(isSuperAdmin)),
        defaultValues: defaultValues,
    });
    var reset = methods.reset, handleSubmit = methods.handleSubmit, _d = methods.formState, isSubmitting = _d.isSubmitting, isDirty = _d.isDirty, watch = methods.watch;
    var values = watch();
    useEffect(function () {
        if (isEdit && currentBooksCashDeskPayment) {
            reset(defaultValues);
        }
        if (!isEdit) {
            reset(defaultValues);
        }
    }, [isEdit, currentBooksCashDeskPayment]);
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 500); })];
                case 1:
                    _a.sent();
                    if (isEdit && currentBooksCashDeskPayment) {
                        dispatch(updateBooksCashDeskPayment({
                            booksCashDeskPaymentId: currentBooksCashDeskPayment === null || currentBooksCashDeskPayment === void 0 ? void 0 : currentBooksCashDeskPayment._id,
                            body: data,
                        }))
                            .unwrap()
                            .then(function (res) {
                            enqueueSnackbar("".concat(translate(res.message)));
                            reset();
                            navigate(PATH_DASHBOARD.books.cashDeskPayments.root);
                        })
                            .catch(function (err) { return enqueueSnackbar("".concat(translate(err.message)), { variant: 'error' }); });
                    }
                    else {
                        dispatch(createBooksCashDeskPayment({ booksCashDeskPayment: data }))
                            .unwrap()
                            .then(function (res) {
                            enqueueSnackbar("".concat(translate(res.message)));
                            reset();
                            navigate(PATH_DASHBOARD.books.cashDeskPayments.root);
                        })
                            .catch(function (err) { return enqueueSnackbar("".concat(translate(err.message)), { variant: 'error' }); });
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(FormProvider, __assign({ methods: methods, onSubmit: handleSubmit(onSubmit) }, { children: _jsx(Grid, __assign({ container: true, spacing: 3 }, { children: _jsx(Grid, __assign({ item: true, xs: 12, md: 8 }, { children: _jsxs(Card, __assign({ sx: { p: 3, overflow: 'visible' } }, { children: [_jsxs(Box, __assign({ gap: 2, display: "grid", gridTemplateColumns: {
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(1, 1fr)',
                            } }, { children: [_jsx(RHFTextField, { type: "number", inputProps: { readOnly: paymentDetails }, name: "amount", label: "Amount *", disabled: paymentDetails }), _jsx(RHFAsyncSelect, { disable: paymentDetails, name: "cashedTo", label: "admin", placeholder: "Select the admin gets the cash *", isSearchable: true, value: values.cashedTo, getOptionLabel: function (option) { return option.name || (option === null || option === void 0 ? void 0 : option.username); }, getOptionValue: function (option) { return option._id; }, fetchData: function (params) { return __awaiter(_this, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, dispatch(getUsers(params))
                                                        .unwrap()
                                                        .then(function (res) { return res; })];
                                                case 1: return [2 /*return*/, _a.sent()];
                                            }
                                        });
                                    }); }, sx: {
                                        padding: '.5rem 0',
                                    } }), isSuperAdmin && (_jsx(RHFAsyncSelect, { disable: paymentDetails || isEdit, name: "office", label: "office", placeholder: "Select an office *", value: values === null || values === void 0 ? void 0 : values.office, isSearchable: true, getOptionLabel: function (option) {
                                        return 'name' in option ? option === null || option === void 0 ? void 0 : option.name : 'no office';
                                    }, getOptionValue: function (option) { return (typeof option === 'string' ? option : option._id); }, fetchData: function (params) { return __awaiter(_this, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, dispatch(getAllOffices(params))
                                                        .unwrap()
                                                        .then(function (res) { return res; })];
                                                case 1: return [2 /*return*/, _a.sent()];
                                            }
                                        });
                                    }); }, sx: {
                                        padding: '.5rem 0',
                                    } })), _jsx(RHFTextField, { inputProps: { readOnly: paymentDetails }, name: "notes", label: "Notes", multiline: true, rows: 3, disabled: paymentDetails })] })), !paymentDetails && (_jsx(Stack, __assign({ alignItems: "flex-end", sx: { mt: 3 } }, { children: _jsx(LoadingButton, __assign({ type: "submit", variant: "contained", disabled: isEdit && !isDirty, loading: isSubmitting }, { children: !isEdit ? 'Create' : 'Save Changes' })) })))] })) })) })) })));
}
